.App-header-user-address {
  background-color: #fff !important;
  border-radius: 50rem;
  margin-left: 2.4rem;
}
.menu-items:focus-visible {
  border: 1px solid #262638;
}
.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* color: white !important; */
}

.user-address {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: var(--font-base) !important;
}

.App-header-user-address:hover {
  background: #808aff14;
}
.App-header-user-address:active {
  background: var(--dark-blue-active);
}

.App-header-user-address .menu-items {
  position: absolute;
  right: 0;
  top: 4.3rem;
  min-width: 15.5rem;
  width: 100%;
  transform-origin: top right;
  border-radius: 0.4rem;
  background: #16182e;
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 2.4rem;
  padding: 1rem 0;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: var(--font-base);
  /* color: #a0a3c4; */
  color: #2f2f2f;
  font-family: "Poppins";
  font-weight: 600;
  padding-bottom: 1.5rem;
  font-size: var(--font-sm);
  padding: 0.85rem 0.8rem;
  /* border-radius: 0.4rem; */
}
.menu-item:hover {
  background: rgba(153, 109, 174, 0.2) !important;
  /* border-radius: 0.4rem; */
  opacity: 1;
  color: rgba(47, 47, 47, 1);
}
.chart-token-menu-items .menu-item:first-child:hover {
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
}
.chart-token-menu-items .menu-item:last-child:hover {
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    margin-left: 0;
  }
  .address-btn {
    display: flex;
    justify-content: space-between;
    width: 4rem;
    height: 4rem;
    background-color: #fff;
  }
  .user-avatar {
    display: none;
  }
}
