.chart-token-selector {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.chart-token-selector--current {
  font-size: 2.1rem;
  font-weight: 700;
  margin-right: 1rem;
  color: #2f2f2f;
}

.chart-token-menu-items.menu-items {
  width: 15.8rem;
  top: 5rem;
  right: unset;
  left: 0;
}

.chart-token-menu-items .menu-item {
  font-size: var(--font-sm);
  height: 3.6rem;
  color: rgba(157, 157, 157, 1);
  justify-content: center;
}

.menu-items {
  background: #fff;
  border: 1px solid #EAEAEA;
  border-radius: 1.2rem;
  cursor: pointer;
  list-style: none;
  min-width: 15.5rem;
  outline: none;
  position: absolute;
  right: 0;
  top: 4.3rem;
  -webkit-transform-origin: top right;
  transform-origin: top right;
  width: 100%;
  z-index: 1000;
}
