.buy-input .InputSection-static-input {
  font-size: var(--font-lg);
}

.buy-input.Exchange-swap-section {
  min-height: 9.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buy-input .TokenSelector-box {
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  /* padding: 0.5rem 0; */
  /* color: #ffffff; */
  min-width: 4.65rem;
  font-size: 2.1rem;
  /* line-height: 2.5rem; */
  justify-content: flex-end;
}
.buy-input .TokenSelector-box:hover {
  color: #7885ff;
}

.buy-input .Exchange-swap-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.4rem;
}

.buy-input .Exchange-swap-max {
  /* position: unset; */
  /* color: white; */
  font-size: var(--font-sm);
  right: 0;
  /* width: 80px; */
}

.buy-input .TokenSelector-caret {
  margin: 0;
  margin-right: -0.5rem;
}

.buy-input .TokenSelector-box-symbol {
  margin-left: 0.8rem;
}

.buy-input .PositionEditor-token-symbol {
  display: flex;
  align-items: center;
  margin-left: 1.4rem;
}

.buy-input .PositionEditor-token-symbol img {
  margin-left: 0.8rem;
  display: none;
}

.buy-input .Exchange-swap-section-top {
  padding-bottom: 0;
}

.buy-input .selected-token {
  /* padding-top: 0.5rem; */
  /* padding-bottom: 0.5rem; */
  display: flex;
  align-items: center;
}

.buy-input .Exchange-swap-balance {
  /* color: white; */
}
