.TradeHistory-row {
  font-size: var(--font-sm);
  padding: 1.5rem;
  margin-bottom: 0.8rem;
  border-radius: 16px;
  background: var(--unnamed, #F7F7F7);
}
.TradeHistory-row:hover{
  border: 1px solid var(--unnamed, #996DAE);
}

.TradeHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;

}

.TradeHistory-item-link {
  display: inline-block;
}

.TradeHistory{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

