@media (max-width: 600px) {
  .referral-common-container {
    padding: 2.4rem 1.6rem;
  }

  .referral-banner-container {
    /* width: 343px; */
    height: 24rem;
    background-image: url('../../img/referrals/rocket_complete.png');
    padding: 3.2rem 0 0 2.4rem;
  }

  .referral-banner-container .referral-banner-p {
    max-width: 22rem;
  }

  .info-container {
    .left {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .user-head-img {
        margin: 0;
      }

      .user-info-item {
        min-width: auto !important;
        line-height: 1 !important;
        margin-bottom: 1rem;

        .lable {
          flex: 2;
        }

        .value {
          flex: 1;
        }
      }
    }

    .right {
      border-top: 1px solid rgba(131, 106, 140, 0.20);
      margin-top: 2.4rem;
      padding-top: 2.4rem;
      padding-left: 0 !important;
      margin-left: 0 !important;
      border-left: none !important;

      .floor-top {
        align-items: flex-start !important;
        flex-direction: column !important;

        .referral-code {
          margin-top: 0.8rem;
        }
      }

      .common-item {
        margin-top: 1.6rem !important;
        align-items: flex-start !important;
        flex-direction: column !important;

        .item {
          margin-top: 0.8rem;
        }
      }
    }

    .handle-code-btn {
      padding: 0rem 6rem !important;
    }
  }

  .level-container {
    .header-container {
      flex-direction: column;

      .left {
        .box-top {
          flex-direction: column;

          .how-to-get {
            margin: 0;
            text-align: center;
          }
        }

        .box-bottom {
          margin: 1.6rem 0;
          text-align: center !important;
        }
      }

      .right {
        flex-direction: column;

        .share-buton {
          margin: 1.8rem 0 0 !important
        }
      }
    }

    .footer-container {

      .common-container {
        justify-content: space-between;

        .title {
          color: var(--unnamed, #9D9D9D) !important;
        }

        .item {
          flex: 1;
          margin: 0 !important;
          text-align: left !important;
          color: var(--unnamed, #2F2F2F);
          /* font-family: Poppins; */
          font-size: 1.6rem;
          font-weight: 600;
        }
      }

      .title-container {
        .item {
          color: var(--unnamed, #2F2F2F);
          font-weight: 500 !important;
        }
      }
    }
  }
}