.StakeV2 .Page-title-section {
  position: relative;
  z-index: 2;
}

.VesterDepositModal-info-rows {
  margin-bottom: 0.8rem;
}

.CompoundModal-menu {
  margin-bottom: 0.8rem;
}

.CompoundModal-menu .Checkbox {
  margin-bottom: 1rem;
}

.CompoundModal-menu .Checkbox span {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
}

.StakeV2-address-input {
  padding: 1.5rem 3.41rem;
  padding-bottom: 0;
}

.StakeV2-buy-gmx-modal .Modal-content {
  max-width: 46.5rem;
}

.StakeV2-address-input input {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.7rem;
}

.StakeV2-boost-bar {
  overflow: hidden;
  vertical-align: middle;
  margin-left: 0.31rem;
  border-radius: 0.2rem;
  width: 1.5rem;
  height: 0.8rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: relative;
}

.StakeV2-boost-icon {
  font-size: 1.085rem;
  z-index: 2;
}

.StakeV2-boost-bar-fill {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
}

.StakeV2-cards {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* grid-gap: 1.5rem; */
  padding: 3.2rem 4rem;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 2.4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}

.StakeV2-cards:last-child {
  margin-bottom: 0;
}

.App-card-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.App-card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem 1.5rem 1.8rem;
}

.App-card-footer .App-card-divider {
  margin-bottom: 1.8rem;
}

.StakeV2-cards-buttons {
  margin: 2.4rem 0;
  display: flex;
  align-items: center;
}

.StakeV2-cards-buttons .btn {
  font-weight: 600;
  font-size: var(--font-md);
  /* line-height: 2.4rem; */
  text-align: center;
  margin-right: 2.4rem;
  display: flex;
  min-width: 16rem;
}

.StakeV2-cards-buttons .btn:last-child {
  margin: 0;
}

.StakeV2-cards-buttons .btns {
  border: 1px solid #2F2F2F;
  background: #fff !important;
  color: #2F2F2F !important;
}

.StakeV2-cards-item .StakeV2-cards-item-row {
  display: flex;
  align-items: center;
  padding-bottom: 1.6rem;
}

.StakeV2-cards-item .StakeV2-cards-item-row:last-child {
  padding: 0;
}

.StakeV2-cards-item-row p {
  margin: 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  color: #2F2F2F;
}

.StakeV2-cards-item-row span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  color: #9D9D9D;
  padding-left: 0.8rem;
}

.StakeV2-cards-left h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #2F2F2F;
  margin: 0 0 2.4rem 0;
}

.StakeV2-cards-left span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-md);
  line-height: 1.6rem;
  color: #9D9D9D;
}

.StakeV2-cards-title {
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
}

.StakeV2-cards-title-box {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: #2F2F2F;
  margin-right: 8rem;
}

.StakeV2-cards-title-box:last-child {
  margin: 0;
}

.StakeV2-cards-title-box span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-md);
  line-height: 1.6rem;
  color: #2F2F2F;
  padding-left: 1.2rem;
}

.StakeV2-cards-item-row {
  display: flex;
  align-items: center;
}

.StakeV2-cards-item-row p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  color: #2F2F2F;
  padding-left: 0.8rem;
}

.StakeV2-cards-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-left: 2px solid rgba(131, 106, 140, 0.2);
  padding-left: 6rem;
  min-width: 30rem;
}

.StakeV2-cards-data-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.StakeV2-cards-data-item span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-md);
  line-height: 1.6rem;
  color: #9D9D9D;
  display: flex;
  align-items: center;
}

.StakeV2-cards-data-item span img {
  margin-left: 0.6rem;
}

.StakeV2-cards-data-item p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-align: center;
  color: #2F2F2F;
  margin: 1rem 0 0 0;
  text-align: right;
}

.Rewards-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-md);
  line-height: 1.6rem;
  color: #2F2F2F;
}

.Rewards-asset {
  display: flex;
  align-items: center;
  padding-top: 3rem;
}

.Rewards-asset-item {
  display: flex;
  align-items: center;
  margin-right: 16rem;
}

.Rewards-asset-item:last-child {
  margin-right: 0;
}

.Rewards-asset-item span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-sm);
  line-height: 1.6rem;
  color: #9D9D9D;
}

.Rewards-asset-item p {
  margin: 0 0 0 0.8rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-sm);
  line-height: 1.6rem;
  color: #2F2F2F
}

.Rewards-btn {
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
}

.Rewards-btn .btn {
  margin-right: 2.4rem;
  min-width: 16rem;
}

.Rewards-btn .btn:last-child {
  margin: 0;
}

.StakeV2-cards-data-item .StakeV2-cards-data-item-small {
  font-size: 2rem;
  line-height: 2rem;
}

.buy-mdca-contianer {
  .btn-item {
    width: 388px;
    height: 4.8rem;
    padding: 8px 139px;
    border-radius: 40px;
    background: var(--eaeaea, #EAEAEA);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      width: 3.2rem;
      margin-right: 0.6rem;
    }

    span {
      color: var(--unnamed, #2F2F2F);
      /* font-family: Poppins; */
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      /* 100% */
    }
  }

  .btn-item-top24 {
    margin-top: 2.4rem;
    ;
  }
}

@media (max-width: 900px) {
  .StakeV2-cards {
    display: flex;
    flex-direction: column;
    padding: 2.4rem 1.6rem;
  }

  .StakeV2-content {
    min-height: 100vh;
  }

  .StakeV2-total-rewards-card {
    grid-row: 4;
  }

  .App-card-footer {
    position: relative;
    padding: 0;
  }

  .StakeV2-cards-left h2 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .StakeV2-cards-left span {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .StakeV2-cards-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
  }

  .StakeV2-cards-title-box {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }

  .StakeV2-cards-title-box span {
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  .StakeV2-cards-buttons {
    margin: 1.6rem 0 1.6rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .StakeV2-cards-buttons .btn {
    margin: 0 0 1.6rem 0;
    flex: 1;
    width: 100%;
  }

  .StakeV2-cards-item .StakeV2-cards-item-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
  }

  .StakeV2-cards-item-row span {
    padding: 0;
  }

  .StakeV2-cards-item-row p {
    padding: 0.8rem 0 0 0;
  }

  .StakeV2-cards-right {
    border-top: 2px solid rgba(131, 106, 140, 0.2);
    border-left: none;
    padding: 2.4rem 0 0 0;
    margin: 2.4rem 0 0 0;
  }

  .StakeV2-cards-data-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;
  }

  .StakeV2-cards-right .StakeV2-cards-data-item:last-child {
    margin-bottom: 0;
  }

  .StakeV2-cards-data-item span {
    font-size: 1.4rem;
    margin: 0;
  }

  .StakeV2-cards-data-item p {
    font-size: 2.4rem;
    margin: 0;
  }

  .StakeV2-cards-data-item span img {
    width: 2rem;
    height: 2rem;
  }

  .StakeV2-cards-data-item :last-child {
    margin: 0;
  }

  .StakeV2-cards-data-item .StakeV2-cards-data-item-small {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .Rewards-asset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
  }

  .Rewards-asset-item {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0.8rem 0;
  }

  .Rewards-asset .Rewards-asset-item:last-child {
    margin: 0;
  }


}