.Exchange {
  max-width: 155rem;
  margin: auto;
  justify-content: space-between;
}

.Exchange-trigger-order-info,
.Exchange-trigger-order-info a {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid #23263b;
}

.Exchange-list-tab-container {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.Exchange-should-show-position-lines {
  font-size: var(--font-sm);
}

.Exchange-should-show-position-lines .active {
  opacity: 1;
}

.Exchange-empty-positions-list-note {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #9D9D9D;
  font-weight: 500;
  font-size: var(--font-md);
}

.Exchange-empty-positions-list-note img {
  margin-bottom: 16px;
}

.position-loading-icon {
  display: inline-block;
  font-size: 1.25rem;
  margin-left: 0.8rem;
  color: #3749e6;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-settings-row {
  margin-bottom: 0.8rem;
}

.Exchange-settings .App-cta {
  margin-top: 1.5rem;
}

.Exchange-content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 4rem;
  padding: 3.1rem;
  padding-top: 0rem;
  margin-top: -1.5rem;
}

.Exchange-position-list-orders {
  white-space: nowrap;
}

.Exchange-lists.large {
  display: block;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 2.4rem;
  margin: 4rem 0 0 0;
  padding: 0 3rem;
}

.Exchange-lists.small {
  display: none;
}

.Exchange-right {
  display: grid;
  grid-template-rows: auto 1fr;
}

.Exchange-leverage-slider-settings {
  font-size: var(--font-sm);
  margin-bottom: 0.8rem;
}

.Exchange-leverage-slider-settings .Checkbox {
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
}

.Exchange-leverage-slider-settings .Checkbox .Checkbox-label span {
  font-weight: 500;
  opacity: 1;
}

.Exchange-wallet-tokens {
  position: relative;
  min-height: 15.5rem;
  display: none;
}

.Exchange-wallet-tokens-content {
  position: absolute;
  top: 1.5rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.Exchange-bottom-header-item {
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 0.155rem;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-bottom-header-item.active {
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-connect-wallet {
  text-align: center;
  cursor: pointer;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
}

.Exchange-swap-connect-wallet:hover {
  background: #272e33;
  border-radius: 0.6rem;
}

.Exchange-swap-settings.Exchange-swap-txns-status {
  text-align: right;
  margin-right: 1.5rem;
}

.Exchange-swap-account .Exchange-swap-address:hover,
.Exchange-swap-account .Exchange-swap-txns-status:hover {
  opacity: 1;
}

.Exchange-leverage-slider {
  margin-top: 1.5rem;
  margin-bottom: 3.4rem;
  padding: 0 0.8rem;
}

.Exchange-swap-leverage-options {
  text-align: right;
}

.Exchange-swap-leverage-option {
  display: inline-block;
  margin-left: 0.8rem;
  padding: 0 0.8rem;
  border-radius: 3px;
  background: #5b2ab0;
  color: white;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-swap-leverage-option.active {
  background: #842ec9;
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-leverage-option:hover {
  opacity: 1;
}

.Exchange-swap-leverage-unlock-icon {
  font-size: 0.8rem;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-swap-placeholder {
  height: 11.175rem;
  position: relative;
}

.Exchange-wave {
  z-index: 1;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
  bottom: 1.5rem;
  -webkit-mask-image: url(img/wave-01.png);
          mask-image: url(img/wave-01.png);
  -webkit-mask-size: 90% 90%;
          mask-size: 90% 90%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-filter: blur(5rem);
          filter: blur(5rem);
  background: linear-gradient(40deg,
      rgba(96, 4, 189, 1) 0%,
      rgba(65, 105, 224, 1) 22%,
      rgba(99, 8, 191, 1) 23%,
      rgba(51, 9, 115, 1) 37%,
      rgba(255, 36, 145, 1) 38%,
      rgba(201, 16, 161, 1) 50%,
      rgba(171, 10, 163, 1) 59%,
      rgba(117, 4, 128, 1) 67%,
      rgba(96, 29, 196, 1) 68%,
      rgba(92, 55, 204, 1) 84%,
      rgba(84, 88, 214, 1) 100%);
  pointer-events: none;
}

.PositionEditor-info-box {
  /* margin-bottom: 2.4rem; */
}

.PositionEditor-keep-leverage-settings .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.PositionEditor-keep-leverage-settings .Checkbox .Checkbox-label {
  color: #9D9D9D;
  font-weight: 600;
}

.PositionEditor-accept-profit-warning .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.PositionEditor-allow-higher-slippage .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.PositionEditor-allow-higher-slippage .Checkbox .Checkbox-label {
  color: #9D9D9D;
  font-weight: 600;
}

.ExchangeChart-range-option {
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-sm);
  cursor: pointer;
  opacity: 0.6;
}

.ExchangeChart-range-option:hover {
  opacity: 0.8;
}

.ExchangeChart-range-option.active {
  opacity: 1;
}

.ExchangeChart {
  position: relative;
  height: 49.6rem;
}

.ExchangeChart-main-price {
  font-size: 1.6rem;
  font-weight: 600;
}

.ExchangeChart-title {
  font-size: 2.15rem;
  font-weight: bold;
}

.ExchangeChart-info-label {
  font-size: 1.2rem;
  color: #9D9D9D;
  font-weight: 600;
}

.ExchangeChart-info-labels {
  font-size: 1.4rem;
  color: #2f2f2f;
  font-weight: 600;
}

.ExchangeChart-top-inner {
  display: grid;
  grid-template-columns: auto auto auto auto auto 1fr;
  grid-column-gap: 4.65rem;
  align-items: center;
  padding-left: 0.3rem;
}

.ExchangeChart-dollar-sign {
  display: inline-block;
  margin-right: 0.465rem;
  opacity: 0.7;
}

.ExchangeChart-main-price-text {
  display: inline-block;
}

.ExchangeChart-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-top {
  position: relative;
  padding: 0.9rem 0.31rem;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 6.975rem;
  z-index: 1;
}

.ExchangeChart-bottom-controls {
  display: inline-block;
}

.ExchangeChart-bottom-controls .Tab .Tab-option {
  width: 4.65rem;
  padding: 0.8rem 0;
}

.ExchangeChart.tv .ExchangeChart-bottom-header {
  position: absolute;
  display: flex;
  top: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 5;
}

.ExchangeChart.tv .ExchangeChart-bottom-stats {
  font-size: var(--font-sm);
  padding: 0.8rem 0;
  margin-left: 3.1rem;
  white-space: nowrap;
  z-index: 2;
}

.ExchangeChart-bottom-stats-label {
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}

.ExchangeChart-bottom-stats-value {
  display: inline-block;
  margin-left: 0.31rem;
  margin-right: 0.8rem;
}

.length-5 .ExchangeChart-bottom-stats-value {
  width: 6.2rem;
}

.length-4 .ExchangeChart-bottom-stats-value {
  width: 5.25rem;
}

.length-3 .ExchangeChart-bottom-stats-value {
  width: 4.35rem;
}

.length-2 .ExchangeChart-bottom-stats-value {
  width: 3.41rem;
}

.length-1 .ExchangeChart-bottom-stats-value {
  width: 2.48rem;
}

.ExchangeChart.tv .ExchangeChart-bottom-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.Exchange-price-tooltip {
  font-size: var(--font-sm);
  background: linear-gradient(90deg, rgba(57, 28, 147, 0.9) 0%, rgba(66, 24, 140, 0.9) 100%);
  padding: 0.9rem;
  border: 1px solid rgba(30, 9, 94, 0.5);
  line-height: 2rem;
}

.Exchange-price-time {
  font-size: 1.25rem;
  font-weight: normal;
}

.Error-modal .Modal-content {
  width: 31rem;
}

.PositionEditor .Modal-content {
  width: 46.8rem;
  position: absolute;
  /* max-height: 80vh; */
  overflow: hidden;
  border: none;
  top: auto;
  bottom: auto;
  background-color: #fff;
}

.PositionEditor .Modal-content .Modal-body {
  -ms-scroll-chaining: smooth;
      overscroll-behavior: smooth;
  max-height: calc(80vh - 8.525rem);
  overflow-y: auto;
}

.PositionEditor .Tab {
  margin-bottom: 2rem;
  border: 2px solid #EAEAEA;
  border-radius: 3.8rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  opacity: 1;
  font-weight: 700;
  font-size: var(--font-md);
}

.PositionEditor-token-symbol {
  font-size: 1.8rem;
  text-align: right;
}

.ExchangeChart-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Exchange-list-modal .Modal-content {
  width: 36rem;
}

.Exchange-list {
  width: 100%;
}

.Position-list-order {
  white-space: nowrap;
  margin-top: 8px;
}

.active-order-tooltip {
  background: var(--dark-blue-hover);
  padding: 0.75rem;
  border-radius: var(--border-radius-sm);
  white-space: normal;
}

.active-order-tooltip>.active-oredr-error {
  margin-top: 0.5rem;
}

.order-error span {
  -webkit-text-decoration-color: var(--error-red);
          text-decoration-color: var(--error-red);
  color: var(--error-red);
}

.order-error .Tooltip-popup {
  white-space: pre-line;
}

.Exchange-list .App-card {
  margin-bottom: 1.5rem;
  background: none;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 2.4rem;
  padding: 2.4rem 1.6rem;
}


.Exchange-list-item-error {
  color: #fa3c58;
  margin-top: 0.465rem;
  font-size: var(--font-sm);
}

.Exchange-list.small {
  display: none;
}

button.Exchange-list-action {
  font-size: var(--font-base);
  padding: 0;
  border-radius: 3px;
  background: none;
  border: none;
  /* color: rgba(255, 255, 255, 0.7); */
  margin: 0 0.3rem;
}

button.Exchange-list-action:hover {
  /* color: rgba(255, 255, 255, 1); */
}

.Exchange-list-action-trigger {
  color: #996DAE;
  font-family: Poppins;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 16px;
}

table.Exchange-list {
  border-collapse: collapse;
  background-color: #fff;
}

table.Exchange-list th,
table.Exchange-list td {
  font-weight: normal;
  text-align: left;
  padding: 1.05rem;
  padding-left: 1.5rem;
  background-color: #fff;
}

table.Exchange-list.Orders th,
table.Exchange-list.Orders td {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

table.Exchange-list tr {
  border-bottom: 1px solid rgba(131, 106, 140, 0.2);
  ;
}

table.Exchange-list tr:last-child {
  border-bottom: none;
}

table.Exchange-list tr:hover {
  background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(38, 43, 71, 1) 100%);
  box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01);
}

table.Exchange-list tr.Exchange-list-header {
  background-color: #fff;
  font-size: var(--font-md);
}

table.Exchange-list tr.Exchange-list-header th {
  padding-top: 0;
  padding-bottom: 1.6rem;
  color: #9D9D9D;
  font-size: 1.6rem;
  font-weight: 500;
}

table.Exchange-list th {
  /* opacity: 0.7; */
}

.Exchange-leverage-box {
  /* margin-bottom: 2.4rem; */
}

.Exchange-swap-box-info {
  /* margin-bottom: 2.4rem; */
}

.Exchange-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: var(--font-sm);
  margin-bottom: 0.8rem;
  font-weight: 600;
}

.Exchange-info-row.top-line {
  border-top: 1px solid rgba(131, 106, 140, 0.2);
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.Exchange-info-label {
  /* opacity: 0.7; */
  margin-right: 0.8rem;
  font-weight: 600;
  color: #9D9D9D;
}

.Exchange-info-value-warning {
  color: #fa3c58;
}

.Confirmation-box-info,
.Confirmation-box-info a,
.Confirmation-box-warning,
.Confirmation-box-warning a {
  font-size: var(--font-sm);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Confirmation-box-info,
.Confirmation-box-info a {
  opacity: 0.7;
}

.Confirmation-box-warning,
.Confirmation-box-warning a {
  color: #fa3c58;
}

.Exchange-swap-usd {
  display: inline-block;
  font-size: var(--font-sm);
}

.Exchange-swap-box {
  width: 43.8rem;
  display: grid;
  position: relative;
}

.Exchange-swap-market-box-title {
  font-size: var(--font-md);
  margin-bottom: 1.25rem;
  font-weight: 700;
}

.Exchange-swap-box-inner {
  padding: 3rem;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 2.4rem;
}

.Exchange-swap-market-box {
  padding: 3rem;
  margin-top: 2.4rem;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.08);
  border-radius: 2.4rem;
}

.Exchange-swap-market-box .App-card-divider {
  /* margin: 0; */
}

.Exchange-swap-box .Radio {
  margin-bottom: 1.05rem;
}

.Exchange-swap-box .Overlay-content-outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15.5rem;
}

.Exchange-swap-box .Overlay-content {
  padding: 1.05rem;
}

.Exchange-swap-box .TokenSelector .Modal,
.Selector .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.Exchange-swap-box .Tab {
  margin-bottom: 2rem;
}

.Exchange-swap-section {
  padding: 1.5rem;
  margin-bottom: 1.05rem;
  border-radius: 0.3rem;
  /* background: linear-gradient(90deg, rgba(30, 34, 61, 0.9) 0%, rgba(38, 43, 71, 0.9) 100%); */
  /* box-shadow: inset 0px 0px 3rem 0.5rem rgba(255, 255, 255, 0.01); */
  /* border: 2px solid #996DAE; */
  border: 1px solid var(--eaeaea, #EAEAEA);
  border-radius: 1.6rem;
}

.Exchange-swap-section:hover {
  padding: 1.5rem;
  margin-bottom: 1.05rem;
  border-radius: 0.3rem;
  border: 1px solid #996DAE;
  border-radius: 1.6rem;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option {
  /* padding: 1.05rem; */
  /* width: 12.4rem; */
  /* height: 4.8rem; */
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;
  background: #EAEAEA;
  border-radius: 3.8rem;
  opacity: 1;
  font-weight: 500;
  border: 1px solid #EAEAEA;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option:last-child {
  margin-right: 0;
}

.Exchange-swap-order-type-tabs {
  margin-top: 2rem;
  border: 2px solid #EAEAEA;
  border-radius: 3.8rem;
}

.Tab.Exchange-swap-order-type-tabs .Tab-option {
  width: 50%;
  text-align: center;
  font-weight: 700;
  background: none;
}

.Tab.Exchange-swap-order-type-tabs .Tab-option.active {
  background: rgba(183, 161, 193, 0.5);
  border-radius: 20rem;
  border: none;
  color: #996DAE;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option-icon {
  transform: scale(0.75);
  vertical-align: middle;
  margin-right: 0.5rem;
}

.Exchange-swap-section-top {
  display: grid;
  grid-template-columns: auto auto;
  font-size: var(--font-sm);
  padding-bottom: 1.25rem;
  font-weight: 600;
  color: #9D9D9D;
  opacity: 1;
}

.Exchange-swap-section-bottom {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 0.31rem;
}

.Exchange-swap-message {
  font-size: var(--font-sm);
  opacity: 0.7;
  margin: 0.5rem 0;
}

.Exchange-swap-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.Exchange-swap-max {
  font-style: normal;
  font-weight: 400;
  position: absolute;
  right: 1.25rem;
  /* right: 0; */
  top: 0;
  color: #2F2F2F;
  border-radius: 4.6rem;
  z-index: 1;
  cursor: pointer;
  font-size: var(--font-sm);
  background: #EAEAEA;
  height: 2.4rem;
  text-align: center;
  line-height: 2.4rem;
  padding: 0 0.5rem;
}

.Exchange-swap-max:hover {
  color: #2F2F2F;
  background: rgba(153, 109, 174, 0.2);
}

.Exchange-swap-section-bottom .TokenSelector-box {
  font-size: 1.8rem;
}

.Exchange-leverage-box .TokenSelector-caret {
  font-size: var(--font-base);
  margin-left: 0.31rem;
}

input.Exchange-swap-input {
  padding: 0;
  max-width: 100%;
  font-size: 1.8rem;
  width: 100%;
  padding-right: 2rem;
  color: #2F2F2F;
}

input.Exchange-swap-input.small {
  max-width: 14.725rem;
}

.Exchange-swap-button-container {
  /* padding-top: 0.31rem; */
  padding-top: 1.6rem;
}

.Exchange-swap-button {
  display: block;
  width: 100%;
}

.Exchange-swap-ball-container {
  position: relative;
  z-index: 1;
}

.Exchange-swap-ball-icon {
  text-align: center;
  display: block;
  transform: rotate(90deg);
  font-size: 2rem;
}

.Exchange-swap-ball {
  position: absolute;
  width: 3.6rem;
  height: 3.6rem;
  left: 50%;
  margin-left: -1.7825rem;
  top: -1.9375rem;
  border-radius: 3.1rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.Exchange-swap-ball:hover {
  /* background: linear-gradient(90deg, rgba(58, 78, 252, 1) 0%, rgba(51, 68, 222, 1) 100%); */
  border: 1px solid #595959;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.Exchange-swap-ball:hover .Exchange-swap-ball-icon {
  opacity: 1;
}

table.Exchange-list-small {
  width: 100%;
  background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
}

table.Exchange-list-small th,
table.Exchange-list-small td {
  font-weight: normal;
  text-align: left;
  padding: 1.05rem;
}

.Exchange-list-card-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.8rem;
}

.Exchange-list-title {
  width: 3.1rem;
  display: inline-block;
  white-space: nowrap;
}

.Exchange-list-info-label {
  font-size: var(--font-sm);
  margin-top: 0.31rem;
}

.Exchange-list-muted {
  font-size: var(--font-sm);
}

.Exchange-list-card .Exchange-list-side {
  text-align: right;
}

.Exchange-list-card .Exchange-list-size-info {
  margin-bottom: 0.8rem;
}

.Confirmation-box {
  font-size: 2rem;
}

.Confirmation-box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 2.4rem 0;
}

.Confirmation-box-main-icon {
  margin: 0.5rem 0;
}

.Confirmation-box-main-icon:after {
  content: "↓";
  font-size: 3rem;
}

.Confirmation-box-main-icon.dot:after {
  content: "•";
}

.Confirmation-box-main-img {
  height: 3.6rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Confirmation-box-main-img img {
  width: 1.6rem;
  height: 1.6rem;
}

.Confirmation-box-main .Confirmation-box-main-title {
  width: 100%;
  background: rgba(153, 109, 174, 0.2);
  border-radius: 1.6rem;
  padding: 1rem 1.6rem;
}

.Confirmation-box-main-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
}

.Confirmation-box-main-title span {
  font-weight: 700;
}

.Confirmation-box-main-title p {
  margin: 0;
  font-weight: 600;
}

.Confirmation-box .Modal-content {
  width: 46.8rem;
}

.Confirmation-box-row {
  margin-top: 1.5rem;
}

.Confirmation-box-button {
  margin-top: 1rem;
  width: 100%;
}

.Exchange-footer {
  text-align: center;
  padding-top: 3.1rem;
  height: 7.75rem;
}

.cancel-order-btn {
  margin-right: 1.5rem;
  background: none;
  border: none;
  padding: 0;
}

.cancel-order-btn:hover {
  opacity: 0.8;
}

.cancel-order-btn:disabled {
  opacity: 0.6;
}

.chart-positions:hover {
  opacity: 0.8;
}

.chart-positions.span:hover {
  opacity: 1;
}

.al-swap .Tooltip-popup {
  min-width: 25rem;
}

.position-list-collateral {
  display: flex;
  align-items: flex-end;
}

.position-list-collateral .edit-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}

.position-list-collateral .edit-icon:hover {
  opacity: 1;
}

.Useful-links-exchange {
  display: none;
  margin: 0;
}

.ExchangeChart-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Position-leverage {
  margin-right: 0.25rem;
}

@media (max-width: 1500px) {
  /* .Exchange-swap-box {
    width: 46.8rem;
  } */
  .Exchange-swap-box {
    width: 38.75rem
  }
}

@media (max-width: 1300px) {
  .Exchange-list.large {
    display: none;
  }

  .Exchange-list.small {
    display: table;
  }
}

@media (max-width: 1100px) {
  .Exchange-swap-box {
    width: auto;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-header {
    display: block;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-stats {
    margin-left: 0;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .Exchange-list-tabs {
    margin-top: 0;
  }

  .Exchange-wallet-tokens {
    display: none;
  }

  .Exchange-swap-placeholder {
    display: none;
  }

  .ExchangeChart {
    height: 38.75rem;
  }

  .Exchange-content {
    grid-template-columns: 1fr;
  }

  .Exchange-lists.large {
    display: none;
  }

  .Exchange-lists.small {
    display: block;
  }

  .Exchange-swap-box .TokenSelector .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .order-error-message {
    white-space: pre-line;
    display: block;
    margin-bottom: 10px;
  }

  .Exchange-swap-box .TokenSelector .Modal,
  .Selector .Modal {
    align-items: center;
    justify-content: center;
  }

  input.Exchange-swap-input {
    max-width: 15.5rem;
  }

  .ExchangeChart-bottom-controls .Tab .Tab-option {
    width: 3.875rem;
    padding: 0.465rem 0;
    font-size: 1.25rem;
  }

  .Exchange-swap-box .TokenSelector .Modal {
    margin: 0 0.5rem;
  }

  .Useful-links-swapbox {
    display: none;
  }

  .Useful-links-exchange {
    display: block;
  }

  .Exchange-swap-box {
    width: auto
  }
}

@media (max-width: 700px) {
  .ExchangeChart-top-inner {
    grid-template-columns: auto auto auto;
    grid-column-gap: 0.8rem;
  }

  .ExchangeChart-title {
    font-size: 1.85rem;
  }

  .ExchangeChart-additional-info {
    display: none;
  }

  .Exchange-content {
    padding: 1.5rem;
    padding-top: 1.5rem;
  }

  .Exchange {
    padding-top: 1.5rem !important;
  }

  .Useful-links-swapbox {
    display: none;
  }
  .Exchange-swap-box {
    grid-template-columns: 1fr;
  }
  .Useful-links-exchange {
    display: block;
  }
  .Exchange-list.small {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 450px) {
  .chart-positions {
    display: none;
  }
}

/* .Exchange-swap-box {
  display: grid;
  position: relative;
  width: 41.85rem
} */
/* @media (max-width: 1100px) {
  .Exchange-swap-box {
    width: auto
  }
} */
/* @media (max-width: 1500px) {
  .Exchange-swap-box {
    width: 38.75rem
  }
} */
