.order-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.order-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--dark-blue-hover);
  border-radius: var(--border-radius-sm);
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.order-list li p {
  margin: 0;
}

.order-list li button {
  font-size: var(--font-sm);
  background: none;
  border: none;
  /* color: white; */
  /* opacity: 0.8; */
}

.order-list li button:hover {
  opacity: 1;
}

.view-orders {
  text-decoration: underline;
  cursor: pointer;
}


.triggerTitle {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  /* letter-spacing: 0em; */
  margin-bottom: 1rem;
}

.triggerBox-stop {
  /* padding: 0 0 1.5rem 0; */
}

.triggerTitles {
  padding-top: 1.5rem;
}

.triggerBox-profit {
  border-top: 1px solid rgba(131, 106, 140, 0.2);
  margin-top: 1.5rem;
}