.history-list {
  width: 100%;
  background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
  font-size: var(--font-sm);
}

table.history-list {
  border-collapse: collapse;
  /* border: 1px solid #1c0e6a; */
}

table.history-list th {
  color: var(--unnamed, #9D9D9D);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

table.history-list td {
  color: var(--unnamed, #2F2F2F);
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  /* text-align: left; */
  /* padding: 1.1rem 1.5rem; */
}

table.history-list tr {
  border-bottom: 1px solid rgba(131, 106, 140, 0.20);
}

table.history-list tr:first-child {
  border-bottom: 2px solid rgba(131, 106, 140, 0.20);
}

table.history-list tr:last-child {
  border-bottom: 1px solid rgba(131, 106, 140, 0.20);
}

.history-list-item:hover {
  background: linear-gradient(90deg, rgba(93, 43, 255, 0.5) 0%, rgba(184, 15, 150, 0.5) 100%);
}

.history-list-item-error {
  color: #ff2491;
}

.history-list-item-type {
  width: 6rem;
}

table.history-list tr.history-list-header {
  background: none;
}

button.history-list-action {
  font-size: var(--font-sm);
  padding: 0;
  border-radius: 3px;
  background: none;
  border: none;
  color: #8b7fe3;
  opacity: 0.9;
}

@media (max-width: 680px) {
  .history-list{
    width: 150%;
  }
}