.info-container {
  background-color: #fff !important;
  display: flex;
  flex-wrap: wrap;

  .left {
    display: flex;
    flex-wrap: wrap;

    .user-head-img {
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;

      img {
        width: 100%;
      }
    }

    .user-info {
      .user-tier {
        color: var(--unnamed, #996DAE);
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .user-info-list {
        margin-top: 18px;
        margin-bottom: 16px;

        .user-info-item {
          min-width: 500px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .lable {
            color: var(--unnamed, #9D9D9D);
            font-size: 16px;
          }

          .value {
            color: #000;
            text-align: right;
            font-size: 20px;

          }
        }
      }

      .claim-btn {
        margin-top: 0
      }
    }
  }

  .right {
    flex: 1;
    padding-left: 40px;
    margin-left: 32px;
    border-left: 1px solid rgba(131, 106, 140, 0.20);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .lable {
      color: var(--unnamed, #9D9D9D);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    .value {
      color: var(--unnamed, #2F2F2F);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    .floor-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .total-rbates {
        .lable {
          margin-bottom: ;
        }

        .value {
          margin-top: 16px;
          font-size: 32px;
        }
      }

      .referral-code {
        .value {
          height: 32px;
        }
      }
    }

    .common-item {
      display: flex;

      .item {
        flex: 1;

        .value {
          margin-top: 4px;

        }
      }
    }

    .trader-referred {
      margin-top: 32px;
    }

    .trading-volume {
      margin-top: 16px;
    }
  }

  .handle-code-btn {
    padding: 0 3rem;
    display: flex;
    align-items: center;
    height: 4.8rem;
    /* line-height: 48px; */
    box-sizing: border-box;
    align-items: center;
    border-radius: 50rem;
    /* background: var(--unnamed, #2F2F2F); */
    color: var(--unnamed, #FFF);
    font-family: Poppins;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

@media (max-width: 900px) {
  .info-container .left .user-head-img {
    margin-right: 0;
  }

  .left .user-info .user-tier {
    text-align: center;
  }
  .user-info .handle-code-btn {
    width: 100%;
    padding: 0 1.6rem !important;
  }
  .right .handle-code-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}