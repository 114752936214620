.connect-wallet-btn {
  background: transparent;
  // padding: 0.8rem 1.4rem;
  padding: 0.75rem 1.3rem;
  display: inline-flex;
  align-items: center;
  border: none;
  font-size: var(--font-sm);
  cursor: pointer;
  // border: 1px solid var(--dark-blue-border);
  // border-radius: var(--border-radius-sm);
  font-weight: 600;
  background: #ffffff;
  border-radius: 50rem;
  // font-family: "Poppins";
  font-weight: 600;
  // font-size: 1.4rem;
  line-height: 2.1rem;
  text-align: center;
  color: #2f2f2f;
  margin-left: 2.4rem;

  .btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .btn-label {
    // font-weight: 400;
    font-weight: 600;
    // font-size: var(--font-sm);
    font-size: var(--font-base);
    white-space: nowrap;
    // margin-left: 0.8rem;
    letter-spacing: 0;
  }
  &:hover {
    background: var(--dark-blue-hover);
  }
  &:active {
    background: var(--dark-blue-active);
  }
}

@media (max-width: 450px) {
  .connect-wallet {
    padding: 0.5rem 0.8rem;
  }
}

@media (max-width: 350px) {
  .connect-wallet-btn {
    height: 3.6rem;
    .btn-icon {
      display: none;
    }
    .btn-label {
      margin-left: 0px;
    }
  }
}
