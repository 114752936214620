.Footer-wrapper {
  text-align: center;
  background: #fff;
  width: 100vw;
  height: 5.6rem;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  border-top: 1px solid rgba(131, 106, 140, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
}

.Footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer-logo img {
  height:3.6rem;
}

.Footer-social-link-block {
  display: flex;
  justify-content: center;
}
.Footer-link {
  color: #9d9d9d;
  font-size: 1.4rem;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}
.Footer-link:hover {
  /* color: white; */
}

.Footer-social-link-block .App-social-link {
  margin-left: 2rem;
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  align-items: center;
  justify-content: center;
}

.Footer-social-link-block .App-social-link:hover img {
  /* filter: brightness(0) invert(1); */
}

.Footer-copyright {
  padding: 1.6rem;
}

.Footer-copyright__text {
  font-family: Circular Std;
  font-size: 1.3rem;
  line-height: 1.3rem;
  letter-spacing: -0.41px;
  color: #a0a3c4;
  display: block;
}
.Footer-links {
  display: flex;
  justify-content: center;
}
.Footer-links > a:not(:last-child),
.Footer-links > .a:not(:last-child) {
  padding-right: 2rem;
}

.Footer-links.mobile-links {
  display: none;
}

@media (max-width: 900px) {
  .Footer-social-link-block .App-social-link {
    margin: 0 1.6rem;
  }

  .Footer-social-link-block {
    margin-top: 1.4rem;
  }

  .Footer-links.web-links {
    display: none;
  }
  .Footer-links.mobile-links {
    display: block;
  }
}

@media (max-width: 580px) {
  .Footer-links {
    flex-direction: column;
  }
  .Footer-links > a {
    padding-right: 0;
    margin-bottom: 0.5rem;
    display: block;
  }
  .Footer-social-link-block {
    margin-bottom: 1.4rem;
  }
  .Footer-wrapper {
    padding-top: 2.5rem;
    flex-direction: column;
    height: auto;
  }
  .home {
    height: 23rem;
  }
  .Footer-links > a:not(:last-child),
  .Footer-links > .a:not(:last-child) {
    padding-right: 0;
  }
  .Footer-social-link-block .App-social-link {
    margin: 0 0.8rem;
  }
}
