.Checkbox {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
}

.Checkbox .Checkbox-icon {
  font-size: 1.8rem;
  color: #2F2F2F;
  margin-right: 0.62rem;
  margin-bottom: 0;
}

.Checkbox.disabled .Checkbox-icon {
  color: #47509e;
}

.Checkbox-icon.inactive {
  color: #2F2F2F;
}

.Checkbox-label {
  display: inline-block;
  vertical-align: middle;
  font-size: var(--font-sm);
  color: #2F2F2F;
  font-weight: 400;
}
