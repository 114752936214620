.level-container {
  width: 100%;
  background-color: #fff !important;
  overflow: hidden;
}

.level-container .header-container {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.level-container .header-container .left {
  .box-top {
    display: flex;
    align-items: center;

    .my-level {
      font-size: 34px;
      font-weight: 600;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }

    .how-to-get {
      margin-left: 20px;
      /* font-family: Poppins; */
      font-size: 14px;
      font-weight: 400;
      /* line-height: 21px; */
      letter-spacing: 0em;
      /* text-align: left; */
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .box-bottom {
    /* font-family: Poppins; */
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.level-container .header-container .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.level-container .header-container .right .share-code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20.4rem;
  height: 4rem;
  border-radius: 0.8rem;
  background: rgba(153, 109, 174, 0.20);
  padding: 0 16px;
  box-sizing: border-box;
}

.level-container .header-container .right .share-code-container .share-code {
  color: #2F2F2F;
  text-align: right;
  font-size: 1.8rem;
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  overflow: hidden;
}

.level-container .header-container .right .share-code-container .copy-button {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.level-container .header-container .right .share-buton {
  margin-left: 4rem;
  display: flex;
  width: 18rem;
  height: 4.8rem;
  /* padding: 1.1rem 11.2rem; */
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 5rem;
  background: #2F2F2F;
  color: #fff;
  font-size: 1.6rem;
  /* font-family: Poppins; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.level-container .body-container .body-wrapper {
  padding-top: 40px;
  display: flex;

  .tower-container {
    flex: 1;
    padding-top:50px;
    .img {
        width: 640px;
      }
  }
  
  .rule-container{
    flex: 1;
    
    .rule-row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: var(--unnamed, #9D9D9D);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      padding-left: 120px;
      margin-top:14px;

      .rule-item{
        flex: 1;
        padding-bottom: 0;
      }
    }
    .rule-title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .active-row {
      color: #996DAE
    }
  }
}

.level-container .footer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .common-container {
    display: flex;

    .type {
      min-width: 180px;
      /* font-family: Poppins; */
      font-size: 16px;
      font-weight: 500;
      /* line-height: 24px; */
      letter-spacing: 0em;
      text-align: right;
    }

    .item {
      width: 100px;
      margin-left: 10px;
      text-align: center;
    }
  }

  .title-container {
    color: var(--unnamed, #9D9D9D);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tradersReferred-container {
    /* font-family: Poppins; */
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
  }

  .tradingVolume-container {
    /* font-family: Poppins; */
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
  }
}

.share-container {
  /* width: 160rem; */
  /* background: url('img/referrals/share-twitter-bg.jpg'); */
  /* background-color: pink; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;

  .data-info-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.20);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    width: 70%;
    border-radius: 1rem;
    padding: 1rem;

    .data-info-container {
      flex-shrink: 0;
      text-align: center;

      .data-value {
        color: var(--unnamed, #FFF);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }

      .data-title {
        color: var(--unnamed, #FFF);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    .invition-code-container {
      color: #fff;

      .code-title {
        font-size: 12px;
      }

      .code {
        font-size: 16px;
        margin-top: 0.6rem;
      }
    }

    .qr-code-container {
      display: flex;
      align-items: center;

      .url-text {
        color: #FFF;
        font-family: Poppins;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        fill: rgba(255, 255, 255, 0.10);
        -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);
        margin-right: 1rem;
      }
    }
  }
}



@media (max-width: 680px) {
  level-container .header-container .right .share-buton {
    margin-left: 0;
  }
  
  .body-container{
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    
    .body-wrapper{
      display: flex;
      width: 200%;

      .tower-container{
        padding-top: 3.2rem !important;
        
        .img{
          width: 100% !important;
        }
      }

      .rule-row {
        font-size: 1.2rem !important;
        line-height: 1 !important;
        padding-left: 8rem !important;
        margin-top: 0.7rem !important;
      }
    }
  }
}