.App-header-network {
    position: relative;
    margin-left: 2.4rem;
    border: 1px solid var(--dark-blue-border);
    border-radius: var(--border-radius-sm);
    height: 3.6rem;
    display: inline-flex;
    align-items: center;
    color: #2f2f2f;
}

.App-header-language {
    margin-left: 0;
}

.App-header-network .network-dropdown {
    display: inline-flex;
    background: #ffffff;
    border-radius: 500px;
    cursor: pointer;
    padding: 0 1.4rem;
}
.network-dropdown-items {
    min-width: 17rem;
    padding-bottom: 0.8rem;
    background: #ffffff;
    border: 1px solid #eaeaea !important;
    box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.08);
    border-radius: 2.4rem;
}
.network-dropdown-items:last-child {
    padding-bottom: 1.4rem !important;
}

.network-dropdown-divider {
    margin-bottom: 0.8rem;
    border-bottom: 1px solid #ffffff29;
}
.network-dropdown:hover {
    /* background: var(--dark-blue-hover); */
    cursor: pointer;
}
.network-dropdown:active {
    background: var(--dark-blue-active);
}

.App-header-network .menu-items {
    position: absolute;
    right: 0;
    top: 4.3rem;
    min-width: 16.6rem;
    /* width: 100%; */
    transform-origin: top right;
    border-radius: 0.4rem;
    background: #16182e;
    list-style: none;
    cursor: pointer;
    outline: none;
    z-index: 1000;
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-shadow: 0px 0px 1.2rem rgba(0, 0, 0, 0.08);
    border-radius: 2.4rem;
    padding: 0 0 1rem;
}

.App-header-network .network-dropdown > button.transparent {
    height: 3.6rem;
    border-radius: 0.4rem;
    border: none;
    pointer-events: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    /* padding: 0 1.4rem; */
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #2f2f2f;
}

.network-dropdown-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.language-popup .Modal-content {
    width: 32rem;
}

.network-dropdown-seperator {
    width: 1px;
    background: var(--dark-blue-border);
    margin: 0.8rem 0;
}

.menu-item-group {
    display: flex;
    align-items: center;
}

.App-header-network .network-dropdown-menu-item {
    display: flex;
    align-items: center;
    /* padding: 0.8rem; */
    justify-content: space-between;
    /* margin-top: 1rem; */

    padding: 0.8rem 0 0.8rem 1.6rem;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: #2f2f2f;
}
.last-dropdown-menu {
    margin-bottom: 0.8rem;
}
.network-popup .Modal-content {
    width: 100%;
    max-width: 29.6rem;
    margin: auto;
}
.network-option {
    cursor: pointer;
    background: var(--dark-blue);
    border: 1px solid var(--dark-blue-border);
    border-radius: 0.4rem;
    padding: 0.8rem 1.5rem;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    margin-bottom: 0.8rem;
}
.network-option:last-child {
    margin-bottom: 0;
}

.network-option img {
    width: 2.5rem;
    margin-right: 1.5rem;
}

.network-option span {
    font-size: var(--font-base);
    letter-spacing: 0.29px;
    color: #fff;
}

.network-option-img-label {
    font-size: var(--font-md);
    line-height: 2rem;
    letter-spacing: 0.29px;
    color: #fff;
}

.App-header-network .dropdown-label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: #9d9d9d;
    padding: 1.6rem 1.6rem 0.4rem;
}

.language-popup .Modal-body {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
}

.language-modal-item {
    cursor: pointer;
    color: #2f2f2f;
    background: #eaeaea;
    border-radius: 1rem;
    /* height: 5.6rem; */
}

.language-modal-item:hover {
    background: #eaeaea !important;
    border-radius: 1rem;
    color: #2f2f2f;
}

.language-modal-item.active:hover {
    background: #996dae !important;
    border-radius: 1rem;
    color: #ffffff;
}

.language-modal-item:hover .menu-item-label {
    color: #2f2f2f !important;
}

.language-modal-item.active:hover .menu-item-label {
    color: #ffffff !important;
}

.language-modal-item.active {
    background: #996dae;
    color: #ffffff;
}

.language-modal-item .menu-item-icon {
    display: none;
}

.language-popup .Modal-body {
    padding: 0 2.4rem 6rem;
}

.menu-item-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
}

.network-dropdown-headings {
    padding: 0.8rem 0.8rem 0.4rem 0.8rem;
    opacity: 0.7;
    font-size: 1.25rem;
}

.App-header-network.small {
    height: 4rem;
}
.App-setting .network-dropdown {
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.App-setting.small .network-dropdown {
    width: 4rem;
    height: 4rem;
}
.App-header-network.small .network-dropdown {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.App-header-network.small .menu-items {
    right: 50%;
    transform: translateX(50%);
}

.App-setting.small .menu-items {
    right: 0;
    transform: none;
}

.network-dropdown-item-label {
    margin-left: 0.8rem;
    white-space: nowrap;
}

.network-dropdown-list {
    display: grid;
    grid-template-columns: 1fr;
}

.menu-item:hover .menu-item-label {
    color: #eee;
}

.active-dot {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-left: 1rem;
}

.active-dot.Arbitrum,
.active-dot.Avalanche,
.active-dot.ArbRinkeby {
    /* background-color: #e84142; */
    background-color: #996dae !important;
}

.network-dropdown-label {
    font-size: 1.25rem;
    margin-bottom: 0.8rem;
    opacity: 0.7;
}

.App-setting .network-dropdown-items {
    padding: 1rem 0;
}

.App-setting {
    border-radius: 100%;
}

.more-options {
    margin-top: 1rem;
}

@media (max-width: 1033px) {
    .App-header-network {
        margin-left: 1.6rem;
    }
    .App-header-language {
        margin-left: 0;
    }
}
@media (max-width: 450px) {
    .App-header-network {
        margin-left: 1.2rem !important;
    }
    .App-header-user-address {
        margin-left: 1.2rem !important;
    }
    .App-header-language {
        margin-left: 0;
    }
    .network-dropdown > button {
        padding: 0 0.5rem;
    }
    .network-dropdown.homepage-header > button {
        padding: 0 0.8rem;
    }
}
