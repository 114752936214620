.DashboardV2 {
  padding-top: 4.65rem;
}

.DashboardV2-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  margin-top: 0.8rem;
}

.DashboardV2-token-cards {
  margin-top: 0.8rem;
}

.token-table-wrapper {
  border: 1px solid #1e2136;
  border-radius: 0.4rem;
  background: #17182c;
  padding: 0 1.5rem;
  text-align: left;
}

.token-table-wrapper .App-card-title {
  justify-content: unset;
}

.token-table-wrapper .App-card-title img {
  margin-top: 0.3rem;
  display: inline-flex;
  margin-left: 0.8rem;
}

.App-card-title-icon {
  display: flex;
  margin-left: 1.2rem;
}

.App-card-title-icon img + img {
  margin-left: 0.6rem;
}

.stats-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.stats-wrapper .App-card {
  width: 100%;
  display: flex;
}

.stats-block {
  width: 100%;
}

.stats-piechart {
  margin: -1rem;
  margin-left: 1.5rem;
}

.stats-block--glp .stats-label {
  grid-row-gap: 0;
}

.stats-label {
  font-size: var(--font-sm);
  line-height: 1.6rem;
  border-radius: 0.4rem;
  padding: 1.05rem;
  letter-spacing: 0.4px;
  text-align: left;
  z-index: 1;
  background: linear-gradient(90deg, #0b0b0f 0%, rgba(10, 13, 28, 1) 100%);
  cursor: pointer;
  color: #fff;
}

.stats-label-color {
  width: 0.4rem;
  height: 100%;
  margin-right: 1.5rem;
}

.Available-network-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.8rem;
}

.Available-network-group .Tooltip {
  display: flex;
}

.Available-network-group .Available-network {
  display: flex;
}

.Available-network-group .Available-network img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.Available-network-group .Tooltip-handle.active .Available-network img {
  -webkit-filter: unset;
          filter: unset;
}

.Available-network-group .Available-network img:hover {
  -webkit-filter: unset;
          filter: unset;
}

.token-table .token-symbol-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 4.65rem;
}

.DashboardV2-token-cards .token-table td:nth-child(3),
.DashboardV2-token-cards .token-table th:nth-child(3) {
  width: 20rem;
}
.mobile-token-card {
  display: flex;
  align-items: center;
}

.token-symbol-text {
  line-height: 1;
}

.mobile-token-card > img {
  margin-right: 0.8rem;
}

@media (max-width: 1024px) {
  .stats-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
  }

  .stats-wrapper .App-card {
    max-width: unset !important;
    margin: 0;
  }
}

@media (max-width: 1100px) {
  .token-table-wrapper {
    display: none;
  }
}

@media (max-width: 900px) {
  .DashboardV2-cards {
    grid-template-columns: 1fr;
    justify-content: center !important;

    .data-card{
      width: 100% !important;
    }
  }

  .DashboardV2-token-cards .token-grid {
    padding-top: 0;
  }

  .DashboardV2 .token-grid .card-container {
    padding: 2.4rem 1.2rem;
  }

  .DashboardV2 .App-card .App-card-title-mobile {
    margin: 0 0 1.6rem 0;
  }
}

@media (max-width: 660px) {
  .stats-wrapper .App-card {
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .stats-piechart {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
}

.DashboardV2{
  .card-container{
    border-radius: 24px;
    border: 1px solid var(--unnamed, rgba(131, 106, 140, 0.20));
    background: var(--unnamed, #FFF);
    padding:1.5rem 8rem  1.5rem 6rem;
  }
  .data-overview-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .data-card{
      box-sizing: border-box;
      width: 32rem;
      height: 9.2rem;
      padding: 0 2.4rem;
      border-radius: 2.4rem;
      border: 1px solid var(--unnamed, rgba(131, 106, 140, 0.20));
      background: var(--unnamed, #FFF);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .label{
        color: var(--unnamed, #2F2F2F);
          /* font-family: Poppins; */
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.6rem;
      }
      .value-container{
        margin-top: 0.8rem;
        color: var(--unnamed, #2F2F2F);
        font-family: Poppins;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.6rem;
      }
    }
  }
  .App-card-title{
    margin-bottom: 2.3rem;
    margin-top:2rem;
    color: var(--unnamed, #2F2F2F);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

  .token-stats-container {
    border-radius: 2.4rem;
    border: 1px solid var(--unnamed, rgba(131, 106, 140, 0.20));
    background: var(--unnamed, #FFF);
    padding: 2.7rem 2.4rem;
    //padding:1.5rem 8rem  1.5rem 6rem;


      .App-card-title-mark{
        display: flex;
        align-items: center;
        .App-card-title-mark-img {
            height: 2.6rem;
            margin-right: 0.8rem;
          }
        .App-card-title-mark-title{
          color: var(--unnamed, #2F2F2F);
          font-family: Poppins;
          font-size: 2.4rem;
          font-style: normal;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 0;
        }
      }
    }

    .label{
      color: var(--unnamed, #2F2F2F);
      font-family: Poppins;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .value{
      color: var(--unnamed, #2F2F2F);
      text-align: right;
      font-family: Poppins;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .DashboardV2-token-cards{
    .token-table{
      td{
        color: var(--unnamed, #2F2F2F);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
    .label{
      color: var(--unnamed, #2F2F2F);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
    }
    .value{
      color: var(--unnamed, #2F2F2F);
      text-align: right;
      font-family: Poppins;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
