.SwapBox-collateral-tooltip-text {
  font-weight: normal;
}

.Exchange-leverage-slider-settings {
  position: relative;
}
.input-leverage {
  width: 7.4rem;
  height: 2.4rem;
  border: 1px solid #EAEAEA;
  border-radius: 5rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 4.8rem;
}
.input-leverage input{
  font-size: 1.4rem;
  color: #2F2F2F;
  font-weight: 600;
}
.input-leverage span {
  padding-right: 0.8rem;
}

/* 止盈止损 */
。stop-profit {
}
.stop-loss {
  padding-bottom: 1.6rem;
}
.stop-loss-header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.4rem;
}
.stop-loss-header-left {
  display: flex;
}
.stop-loss-header-left p {
  margin: 0;
  padding-right: 0.4rem;
}
.stop-loss-header span {
  color: #FD5760;
}
.stop-loss-slide {
  background: #EAEAEA;
  border-radius: 5rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  height: 3.2rem;
  font-weight: 600;
  margin-top: 0.8rem;
}
.stop-loss-slide ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
}
.stop-loss-slide ul li {
  padding: 0;
  color: #9D9D9D;
  cursor: pointer;
}
.stop-loss-slide ul li.active {
  color: #996DAE;
}
.slider-price {
  background: #FFFFFF;
  border-radius: 5rem;
  width: 10rem;
  height: 2.8rem;
  margin: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-price input {
  color: #2F2F2F;
  font-size: 1.2rem;
  text-align: center;
}
.slider-price ::-webkit-input-placeholder {
  font-weight: 600;
}

.take-profit {
  padding-bottom: 2.4rem;
}
.take-profit span {
  color: #1AC47C;
}
