.commonButton {
  background: #2F2F2F;
  color: #FFF;
  width: 41.2rem;
  height: 4.8rem;
  border: 0;
  border-radius: 50rem;
  font-family: Poppins;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home {
  width: 100%;
  min-height: 100%;
  padding: 6.4rem 36rem 6.8rem 24.4rem;
  background: #000000;
}

.Home-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 50.4rem;
    height: 50.4rem;
  }
}

.Home-top-left {
  width: 76.8rem;
  padding-top: 3.6rem;
  display: flex;
  flex-direction: column;

  span {
    color: #FFF;
    font-family: Poppins;
    font-style: normal;
  }

  .Home-top-left-one {
    font-size: 6rem;
    font-weight: 400;
    line-height: 5.4rem;
  }

  .Home-top-left-two {
    font-size: 6rem;
    font-weight: 700;
    line-height: 5.4rem;
    margin-top: 1.6rem;
  }

  .Home-top-left-three {
    width: 100%;
    font-size: 2rem;
    font-weight: 400;
    line-height: 160%;
    margin-top: 4rem;
  }

  .Home-top-left-button {
    @extend .commonButton;
    background-color: #FFF;
    color: #292929;
    width: 24rem;
    margin-top: 4rem;
    border-radius: 50rem;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 600;
  }
}


.Home-top-achievement {
  margin-top: 8.4rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.Home-top-data {
  color: #FFF;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  .Home-top-data-number {
    font-family: Dotted Songti Square;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 4rem;
    margin-bottom: 1.6rem;
  }
}

.Home-content-one {
  background-color: #ededed;
  width: 100%;
  height: 72rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Home-content-one-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 4rem;
    text-align: center;
    font-family: Poppins;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    text-transform: uppercase;
  }

  span {
    width: 36rem;
    color: #2F2F2F;
    text-align: center;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 160%;
  }

  .Home-content-one-button {
    @extend .commonButton;
    background-color: #FFF;
    color: #292929;
    width: 24rem;
    margin-top: 4rem;
  }
}

.Home-content-two {
  background-color: #f9f9f9;
  width: 100%;
  height: 72rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20rem 0 20rem;

  & > span {
    color: #2F2F2F;
    width: 46rem;
    font-family: Poppins;
    font-size: 4rem;
    font-weight: 700;
    line-height: 138.5%;
    text-transform: uppercase;
  }
}

.Home-content-two-card {
  background-color: #FFF;
  border-radius: 2rem;
  display: flex;
  flex-flow: column nowrap;
  width: 44.4rem;
  height: 32rem;
  padding: 2.4rem 1.6rem;
  color: #2F2F2F;
}

.Home-content-two-card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;

  .Home-content-two-card-top-left {
    display: flex;
    align-items: center;
  }

  img {
    width: 4rem;
  }

  span {
    font-family: Poppins;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 138.5%;
    text-transform: uppercase;
    margin-left: 1.2rem;
    color: #2F2F2F;
  }

  .Home-content-two-link {
    font-size: 1.6rem;
    color: #1AC47C;
    margin-top: 1.2rem;
  }
}


.Home-content-two-card-content {
  margin-top: 1.6rem;
  font-family: Poppins;
  font-size: 1.6rem;
  font-weight: 400;
}

.Home-content-two-card-grid {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 2.8rem 2.8rem;
  font-family: Poppins;
  font-size: 1.6rem;
  font-weight: 400;

  .Home-content-two-card-grid-right {
    justify-self: end;
    font-weight: 600;
  }
}

.Home-content-two-button {
  @extend .commonButton;
  margin-top: 1rem;
}

.Home-content-three {
  width: 100%;
  height: 72rem;
  padding: 0 24.4rem;
  background-color: #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Home-content-three-left {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 45.6rem;
  height: 23.2rem;
  color: #2F2F2F;
  font-family: Poppins;

  .Home-content-three-left-top {
    font-size: 4rem;
    font-weight: 700;
    line-height: 138.5%;
    text-transform: uppercase;
  }

  .Home-content-three-left-middle {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.4rem;
  }

  .Home-content-three-left-button {
    @extend .commonButton;
  }
}

@media (max-width: 1766px) {
  .Home {
    padding: 6.4rem 33.2rem 6.8rem 22.4rem;
  }
  .Home-content-two {
    padding: 0 10rem;

    & > span {
      font-size: 3rem;
      width: 34.4rem;
    }

    .Home-content-two-card {
      width: 34.4rem;
      height: 32rem;
      padding: 2.8rem 1.6rem 2.4rem;

      .Home-content-two-card-top {
        height: 3.2rem;

        img {
          width: 3.2rem;
          height: 3.2rem;
        }

        span {
          margin-left: 1rem;
        }
      }

      .Home-content-two-card-content {
        width: 31.2rem;
        margin-top: 1.6rem;
        line-height: 140%;
      }

      .Home-content-two-card-grid {
        margin-top: 1.6rem;
        row-gap: 1.2rem;
      }

      .Home-content-two-button {
        width: 31.2rem;
        margin-top:2.4rem;
      }
    }
  }
}

@media (max-width: 1706px) {
  .Home-content-three {
    padding: 0 10rem;
  }
}

@media (max-width: 1328px) {
  .Home {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 6.4rem 24.8rem 6.8rem 16.8rem;
    .Home-top {
      width: 100%;
      display: flex;
      flex-flow: column-reverse nowrap;
      align-items: center;

      img {
        width: 40rem;
        height: 40rem;
      }

      .Home-top-left {
        margin-right: 0;
        width: 100%;
      }
    }

    .Home-top-achievement {
      width: 100%;
      margin-top: 8.4rem;
    }
  }
  .Home-content-three {
    padding: 0 5rem;
  }
}

@media (max-width: 1260px) {
  .Home-content-two {
    padding: 0 2rem;
  }
}

@media (max-width: 1100px) {
  .Home-content-one {
    padding-bottom: 5.6rem;
  }
  .Home-content-two {
    height: 100%;
    padding: 5.6rem 2rem 0;
    display: flex;
    flex-flow: column nowrap;

    & > span {
      padding-bottom: 1.6rem;
    }

    .Home-content-two-card {
      margin-top: 2.4rem;
    }
  }
}

@media (max-width: 976px) {
  .Home {
    padding: 6.4rem 18rem 6.8rem 12.2rem;

    .Home-top-achievement {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      margin-top: 6rem;

      .Home-top-data {
        margin-bottom: 2.4rem;
      }
    }
  }
  .Home-content-one {
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    background-color: #ededed;
    flex-flow: column nowrap;

    .Home-content-one-card {
      margin-top: 4rem;

      h1 {
        margin-top: 2.4rem;
      }

      span {
        margin-top: 1.6rem;
        width: 34.4rem;
      }

      div {
        margin-top: 2.4rem;
      }
    }
  }
  .Home-content-three {
    display: flex;
    flex-flow: column-reverse nowrap;
    height: 100%;
    padding: 6rem 1.6rem 12rem;

    img {
      width: 37.6rem;
    }

    .Home-content-three-left {
      width: 34.4rem;
      height: 18.8rem;

      .Home-content-three-left-top {
        font-size: 3.2rem;
      }

      .Home-content-three-left-middle {
        font-size: 1.6rem;
      }

      .Home-content-three-left-button {
        font-family: Poppins;
        border-radius: 50rem;
        background: #2F2F2F;
        width: 34.4rem;
        height: 4.8rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 600;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media (max-width: 580px) {
  .Home {
    padding: 4rem 1.6rem 6.8rem;
    .Home-top {
      img {
        width: 34.3rem;
        height: 34.3rem;
      }

      .Home-top-left {
        margin-top: -2.6rem;

        .Home-top-left-one {
          font-size: 3.2rem;
        }

        .Home-top-left-two {
          margin-top: 1.2rem;
          font-size: 3.2rem;
          font-weight: 700;
          line-height: 3.6rem;
        }

        .Home-top-left-three {
          width: 100%;
          margin-top: 2.4rem;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 140%;
        }
      }
    }
  }
  .Home-content-two {
    height: 100%;
    padding-bottom: 5.6rem;
  }

  .Home-content-three {
    padding-bottom: 21rem;
  }
}

